<template>
    <main>
        <!--头图-->
        <img src="@/assets/img/about/about-banner.png" alt="许孩子一个确定而美好的未来" class="top-img">

        <!--模块1:财商少年介绍-->
        <div class="module1 clear-before">
            <img src="@/assets/img/main/FQLogo.png" alt="财商少年" class="logo">
            <div class="text1">
                <p>广东财商少年信息科技有限公司（以下简称“财商少年”），长期从事于财商教育行业，有着专业的导师团队和丰富的教学经验。
                    通过举办全国现金流游戏大赛，以及APP、小程序、公众号等线上渠道，为用户搭建了一个集学习、交流、分享和实用为一体的平台， 方便财商导师和家长、青少年建立常态联系，以此引领家庭财商发展。
                    “财商少年”目前在多个地区和学校有较为成熟的教学方法和模式，致力于推进青少年儿童的财商普及，传播正确的财商观念，培养青少 年形成正确的金钱观、消费观以及团队合作精神，
                    成为全面发展的高素质人才。</p>
                <p>自2014年起，“财商少年”开始走进校园、社区， 目前已在广州、成都、太原、湖南、茂名等18个地区开展多场财商教育课程。
                    培训的少儿学员和家长人数逾5000人。同时致力于培养更多优秀的专业少年财商导师，为青少年儿童传授正确财商知识。</p>
            </div>
        </div>

        <!--模块2:企业文化-->
        <div class="module2">
            <img src="@/assets/img/about/bg1@2x.png" alt="企业文化" class="img-culture">
            <div class="text2">
                <h2>企业文化</h2>
                <p>“财商少年”是专注于青少年财商发展的专业财商品牌, 致力于推进青少年儿童的财商普及,传播正确的财商观念, 培养青少年形成正确的金钱观、消费观以及团队合作精神, 成为全面发展的高素质人才。
                    财商少年从2014年起开始走进学校、社区,目前已在全国18个地区开展财商教育普及课程, 同时致力于培养更多优秀的专业少年财商导师,为青少年儿童传授正确财商知识。</p>
            </div>
        </div>

        <!--模块3:师资力量-->
        <div class="module3">
            <div class="text3">
                <h2>师资力量与课程核心团队</h2>
                <p>“财商少年”旗下拥有一支实力雄厚的师资力量队伍， 他们中既有国际名校的经济学博士， 也有香港理工大学的金融硕士。 这支队伍是“财商少年”培养优秀的少年财商导师的主力军，
                    也是财商少年财商研究、社会服务、文化传承的中坚力量。</p>
            </div>

            <div class="teacher-banner clearfix"
                 v-for="(teacher, index) in teachers"
                 :key="teacher.imgMain"
                 v-show="index === currentTeacher">
                <img :src="require(`@/assets/img/about/${teacher.imgMain}`)" :alt="teacher.name">
                <div class="teacher-text">
                    <h3>{{teacher.name}}</h3>
                    <div class="line"></div>
                    <p v-for="introduction in teacher.introductions" :key="introduction">{{introduction}}</p>
                </div>
            </div>

            <ul class="teachers">
                <li v-for="(teacher, index) in teachers"
                    :key="teacher.name"
                    @click="handleClick(index)"
                    class="teacher">
                    <div class="img-holder" :class="{active: index === currentTeacher}">
                        <img :src="require(`@/assets/img/about/${teacher.imgSub}`)" :alt="teacher.name">
                    </div>
                    <p class="teacher-name">{{teacher.name}}</p>
                </li>
            </ul>
        </div>

        <!--模块4:it团队-->
        <div class="module4">
            <img src="@/assets/img/about/bg2@2x.png" alt="专业it团队">
            <div class="text4">
                <h2>专业IT团队自主研发</h2>
                <p>自主IT团队研发实力强大，打造亲子共修平台。</p>
                <p>财商学习并不是个人的事，是全体家庭成员都需要共同修炼的人生课程。家长们自己要通过不断学习成为合格的家庭财务官， 更要与孩子们一起“大手牵小手，未来一起走”共同提高财商知识。</p>
            </div>
        </div>
    </main>
</template>

<script>
    export default {
        name: "About",
        metaInfo: {
            title: '财商少年官网-关于我们',
            meta: [
                {
                    name: 'keyWords',
                    content: '财商,广东财商少年信息科技有限公司,中国财商少年,中国财商学院,全国现金流游戏大赛,财商教育课程体系,少儿财商教育,财商导师培养'
                },
                {
                    name: 'description',
                    content: '中国财商少年是广东财商少年科技有限公司旗下品牌，我们长期从事财商教育行业，有着专业的导师团队和丰富的教学经验，每年举办全国现金流游戏大赛，致力于培养优秀的财商导师，深入少儿财商教育事业。'
                }
            ]
        },
        data() {
            return {
                teachers: [
                    {
                        name: '叶劲枝',
                        introductions: [
                            '香港理工大学财富管理硕士',
                            '企业金融硕士',
                            '企业金融专家',
                            '国际专业财富管理协会执行主席等'
                        ],
                        imgMain: 'yejingzhi.jpg',
                        imgSub: 'about_yejingzhi.jpg'
                    },
                    {
                        name: '欧阳虹',
                        introductions: [
                            '中国管理科学研究院特约研究员',
                            '中国亚太经济发展中心高级研究员和广东省南方智库发展研究院研究员',
                            '2002年论文“提升中国企业国际竞争力”获国务院和中国社会科学院的经济学家论坛二等奖',
                            '2002年“论国有企业人力资源开发和管理”等三篇论文获中国管理科学研究院一等奖',
                            '2001年《论入世后中国企业的发展战略》论文获世界华人协会国际优秀论文奖'
                        ],
                        imgMain: 'ouyanghong.jpg',
                        imgSub: 'about_ouyanghong.jpg'
                    },
                    {
                        name: '符金泳',
                        introductions: [
                            '国家高级理财规划师（ChFP）',
                            '湛江海洋大学的特聘创业成长导师',
                            '最佳校园财商教育导师',
                            '理财规划、财商教育专家',
                        ],
                        imgMain: 'fujinyong.jpg',
                        imgSub: 'about_fujinyong.jpg'
                    },
                    {
                        name: '叶柏强',
                        introductions: [
                            '广东石油化工学院大学生创业成长导师',
                            '国际专业财富管理协会星际财商导师',
                            '国家注册一级理财规划师',
                        ],
                        imgMain: 'yebaiqiang.jpg',
                        imgSub: 'about_yebaiqiang.jpg'
                    },
                    {
                        name: '陈洁源',
                        introductions: [
                            '金融、工商管理双硕士',
                            '中国注册会计师',
                            '国际注册财务策划师',
                            '英国会计师协会会员',
                            '全国企业经营管理人才库高端成员'
                        ],
                        imgMain: 'chenjieyuan.jpg',
                        imgSub: 'about_chenjieyuan.jpg'
                    },
                ],
                currentTeacher: 0
            }
        },
        methods: {
            handleClick(index) {
                this.currentTeacher = index
            }
        }
    }
</script>

<style lang="scss" scoped>
    // 头图
    .top-img {
        display: block;
        width: 100%;
    }

    // 第一个模块
    .module1 {
        height: 580px;
        background: #fffcf6;
    }

    .logo {
        display: block;
        width: 300px;
        margin: 70px auto;
    }

    // 正文:div
    .text1 {
        width: 900px;
        margin: 0 auto;
        font-size: 16px;
        line-height: 30px;
        text-indent: 32px;
        font-weight: bold;
    }

    // 第二个模块: 企业文化
    .module2 {
        position: relative;
        padding-top: 64px;
    }

    .img-culture {
        display: block;
        margin: 0 auto;
    }

    .text2 {
        width: 555px;
        position: absolute;
        left: 56%;
        top: 12%;

        h2 {
            font-family: Trends;
            font-size: 36px;
            color: #000000;
        }

        p {
            font-size: 16px;
            line-height: 30px;
            text-indent: 32px;
            margin: 30px 0 0 50px;
            font-weight: bold;
        }
    }

    // 第三个模块:师资力量
    .module3 {
        padding-top: 80px;
    }

    .text3 {
        margin: 0 auto 30px;
        width: 58%;
        text-align: center;

        h2 {
            font-weight: normal;
            font-family: Trends;
            font-size: 36px;
            margin-bottom: 30px;
        }

        p {
            font-size: 16px;
            line-height: 30px;
            text-indent: 32px;
            font-weight: bold;
        }
    }

    // 导师的大图和详细介绍
    .teacher-banner {
        width: 800px;
        height: 450px;
        margin: 0 auto;
        /*background: #0093db;*/
        background: linear-gradient(to right top, #0599e5, #0555a6);
        overflow: hidden;
        position: relative;

        img {
            float: left;
            width: 400px;
        }
    }

    // 导师的文字介绍
    .teacher-text {
        float: left;
        color: #ffffff;
        font-weight: bold;
        position: absolute;
        left: 430px;
        bottom: 20px;

        h3 {
            font-size: 28px;
        }

        .line {
            width: 40px;
            height: 4px;
            background: #ffffff;
            margin: 20px 0;
        }

        p {
            width: 340px;
            font-size: 16px;
            line-height: 30px;
        }
    }

    // 多个导师的头像
    .teachers {
        width: 1200px;
        margin: 50px auto 50px;
        display: flex;
        justify-content: space-around;
    }

    .teacher {
        cursor: pointer;
    }

    .teacher-name {
        text-align: center;
        font-size: 28px;
        color: #333333;
        font-weight: bold;
    }

    .img-holder {
        height: 180px;
        width: 180px;
        // 边框颜色
        /*box-shadow: 0 0 3px 3px #999999;*/
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 200px;
        }
    }

    // 导师边框颜色改变
    .active {
        box-shadow: 0 0 3px 3px #0093db;

    }

    // 第四个模块: it团队
    .module4 {
        position: relative;
        img {
            display: block;
            width: 100%;
        }
    }
    .text4 {
        width: 47%;
        position: absolute;
        top: 65px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-weight: bold;
        // 字体间距
        letter-spacing: 2px;
        h2 {
            color: #fefefe;
            font-size: 36px;
            margin-bottom: 20px;
            font-family: Trends;
            font-weight: normal;
        }
        p:nth-child(2) {
            font-size: 24px;
            color: #ffd805;
            margin-bottom: 30px;
        }
        p:last-child {
            font-size: 16px;
            color: #ffffff;
        }
    }
</style>
